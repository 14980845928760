<template>
  <div>
    <div>
      <div
        v-if="loader"
        class="text-center mt-5"
      >
        <b-spinner
          type="grow"
          label="Loading..."
        />
      </div>
      <b-row v-else>
        <b-col lg="12">
          <b-card>
            <validation-observer
              ref="notifyForm"
              tag="form"
            >
              <b-row>
                <b-col md="6">
                  <b-form-group
                    label="Title"
                    label-for="title"
                  >
                    <VueEmoji
                      :value="notifyData.title"
                      height="40px"
                      width="300px"
                      @input="onInput"
                    />
                  </b-form-group>
                  <!-- Name -->
                  <!-- <validation-provider
                    #default="validationContext"
                    name="Title"
                    rules="required"
                  >
                    <b-form-group
                      label="Title"
                      label-for="title"
                    >
                      <VueEmoji
                        :value="notifyData.title"
                        @input="onInput"
                      />
                      {{ notifyData.title }}
                      <b-form-input
                        id="title"
                        v-model="notifyData.title"
                        autofocus
                        :state="getValidationState(validationContext)"
                        trim
                        placeholder="Title"
                      />
                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider> -->
                </b-col>
                <b-col md="6">
                  <validation-provider
                    #default="validationContext"
                    name="Users"
                  >
                    <b-form-group
                      label="Users"
                      label-for="Users"
                    >
                      <v-select
                        v-model="userIds"
                        :options="paginated"
                        :filterable="false"
                        multiple
                        label="name"
                        :reduce="allUsers => allUsers"
                        @open="onOpen"
                        @close="onClose"
                        @search="searchUsers"
                      >
                        <template #list-footer>
                          <li
                            v-show="hasNextPage"
                            ref="load"
                            class="loader mx-2"
                          >
                            Loading more options...
                          </li>
                        </template>

                      </v-select>

                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col md="6">
                  <!-- Name -->

                  <b-form-group
                    label="Body"
                    label-for="body"
                  >
                    <div class="vueEmojiBody">
                      <VueEmoji
                        :value="notifyData.body"
                        width="400px"
                        @input="onInputBody"
                      />

                    </div>

                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  class="mt-50"
                >
                  <b-button
                    v-if="!loader"
                    variant="primary"
                    class="mr-1"
                    @click="pushNotification()"
                  >
                    Save
                  </b-button>
                  <b-button
                    v-if="loader"
                    variant="primary"
                    disabled
                    class="mr-1"
                  >
                    <b-spinner
                      small
                      type="grow"
                    />
                    Loading...
                  </b-button>
                </b-col>
              </b-row>
            </validation-observer>
          </b-card>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import formValidation from '@core/comp-functions/forms/form-validation'
import { required } from '@validations'
import { ref } from '@vue/composition-api'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from 'axios'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import vSelect from 'vue-select'
import VueEmoji from 'emoji-vue'
import previewImage from '@/global-compositions/previewImage'
import user from '../users/customer/users'
import handleFormData from '@/global-compositions/handleFormData'

export default {
  // eslint-disable-next-line vue/no-unused-components
  components: {
    vSelect, VueEmoji,
  },
  mixins: [togglePasswordVisibility, previewImage],
  data() {
    return {
      required,
      loader: false,
      notifyData: [],
      userIds: '',
      pagination: {
        current_page: 1,
      },
      observer: null,
      limit: 15,
      search: '',
    }
  },
  setup() {
    const { userForm, users } = user()
    const { getValidationState } = formValidation()
    const { formData, setFormData } = handleFormData()
    const allUsers = ref([])

    return {
      getValidationState,
      userForm,
      users,
      formData,
      setFormData,
      allUsers,
    }
  },
  computed: {
    filtered() {
      return this.allUsers
    },
    paginated() {
      return this.filtered.slice(0, this.limit)
    },
    hasNextPage() {
      return this.pagination.current_page < this.pagination.last_page
    },
  },
  mounted() {
    this.getusers()
    this.observer = new IntersectionObserver(this.infiniteScroll)
  },
  methods: {
    // setSelectedOption(options, value) {
    //   if (value.value === undefined) {
    //     console.log(value, 'value')
    //     const option = options.find(x => x.name === value.name)
    //     console.log(option, 'option')
    //     return option.name
    //   }
    //   console.log(value, '2')

    //   return value.name
    // },
    onInput(event) {
      this.notifyData.title = event.data
    },
    onInputBody(event) {
      this.notifyData.body = event.data
    },
    async onOpen() {
      if (this.hasNextPage) {
        await this.$nextTick()
        this.observer.observe(this.$refs.load)
      }
    },
    onClose() {
      this.observer.disconnect()
    },
    async searchUsers(searchQuery) {
      await axios.get(`users?search=${searchQuery}&role=2`).then(res => {
        if (res.status === 200) {
          this.allUsers = res.data?.data
          this.pagination = res.data.meta
        }
      })
    },
    async infiniteScroll([{ isIntersecting }]) {
      if (isIntersecting) {
        this.pagination.current_page += 1
        axios
          .get(`users?page=${this.pagination.current_page}`)
          .then(response => {
            if (response.data.data.length > 1) {
              response.data.data.forEach(item => this.allUsers.push(item))
              this.limit += 15
            }
          })
      }
    },

    pushNotification() {
      if (this.$route.params.id) {
        this.loader = true
        this.setFormData(this.notifyData)

        this.formData.append('_method', 'PUT')
        axios
          .post(
            `generalNotification/user_id?${this.notifyData.users}`,
            this.formData,
          )
          .then(res => {
            if (res.status === 200) {
              this.getusers()
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Sent Succesfully',
                  icon: 'BellIcon',
                  variant: 'success',
                },
              })
            }
          })
          .catch(e => {
            if (e) {
              Object.assign(this.validationErrors, e.response.data.errors)
            }
          })
          .finally(() => {
            this.loader = false
          })
      } else {
        this.$refs.notifyForm.validate().then(success => {
          if (success) {
            this.loader = true
            this.setFormData(this.notifyData)
            const result = this.userIds.map(a => a.id)
            let apiUrl = ''
            if (result[0] == null) {
              apiUrl = 'generalNotification'
            } else {
              apiUrl = `generalNotification?user_id=${result}`
            }
            axios
              .post(
                apiUrl,
                this.formData,
              )
              .then(res => {
                if (res.status === 200) {
                  this.getusers()

                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Sent Succesfully',
                      icon: 'BellIcon',
                      variant: 'success',
                    },
                  })
                }
              })
              .catch(e => {
                if (e) {
                  Object.assign(this.validationErrors, e.response.data.errors)
                }
              })
              .finally(() => {
                this.loader = false
                this.notifyData = {}
                this.userIds = ''
              })
          }
        })
      }
    },
    async getusers() {
      await axios.get('users').then(res => {
        if (res.status === 200) {
          this.allUsers = res.data?.data
          const all = {
            name: 'All Users',
            id: null,
          }
          this.allUsers.unshift(all)
          this.pagination = res.data.meta
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.emoji-wysiwyg-editor{
  border: 1px solid #d8d6de !important;
    border-radius: 0.357rem !important;
    height: 39px !important;
    padding-top: 10px !important;

}
.emoji-picker-icon{
  top: 7px !important;
}
.vueEmojiBody .emoji-wysiwyg-editor{
  height: 100px !important;

}
.emoji-wysiwyg-editor :focus{
  border: #30A9FF !important;
    border-bottom-color: #30A9FF !important;
    box-shadow: 0 3px 10px 0 rgb(34 41 47 / 10%);
}

</style>
